import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin:false,
    subUnitSize: 'mini',
    unit: [],
    goodsTypeArr: [],
    merchantArr: [],
    areaArr: [],
    routerArr:[
      {
        path: '/home/index',
        title: '首页'
      }
    ]
  },
  mutations: {
    setUnit: (state, payload) => state.unit = payload,
    setGoodsType: (state, payload) => state.goodsTypeArr = payload,
    setMerchant: (state, payload) => state.merchantArr = payload,
    setArea: (state, payload) => state.areaArr = payload,
    setRouterArr(state, payload) {
      let o =state.routerArr.some(item=>{
        return item.path == payload.path;
      })
      !o && state.routerArr.push(payload)
    }
  },
  actions: {
  },
  getters: {
    unit: state => state.unit,
    goodsTypeArr: state => state.goodsTypeArr,
    merchantArr: state => state.merchantArr,
    areaArr: state => state.areaArr,
    routerArr: state => state.routerArr,
  },
  modules: {
  },
})
