<template>
  <el-header class="shadow page-header__wrap">
    <!-- <div class="curRouter">
      <left-right-swiper-scroll>
        <div v-for="(item,index) in routerArr" :key="item.path" :class="item.path == $route.path ? 'item active' : 'item'" @click="handleRouter(item.path)">
          <router-link class="path" :to="item.path">{{item.title}}</router-link>
          <i class="el-icon-close closeImg" @click="handleClone(index)"></i>
        </div>
      </left-right-swiper-scroll>
    </div> -->
    <div class="title">
      <span class="site">当前位置：</span>
      {{ $route.matched[0].meta.title }}
      <span> > </span>
      {{ $route.meta.title }}
    </div>
    <div class="refresh" >
      <el-button  icon="el-icon-refresh" size="small" @click="handleRefresh">刷新</el-button>
    </div>
  </el-header>
</template>

<script>
// import leftRightSwiperScroll from '@/layout/leftRightSwiperScroll.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'PageHeader',
  // components: { 
  //   leftRightSwiperScroll
  // },
  data() {
    return {
      curIndex: 0,
      curPath: this.$route.path,
    }
  },
  computed: {
    ...mapGetters(['routerArr'])
  },
  methods: {
    //刷新;
    handleRefresh() {
      window.location.reload();
    },
    // handleRouter(path) {
    //   this.curPath = path
    // },
    // handleClone(index) {
    //   console.log(this.routerArr)
    //   if(index != 0) {
    //     this.routerArr.splice(index, 1);
    //     let pathUrl = this.routerArr.at(-1).path
    //     console.log(pathUrl)
    //     this.$router.push(pathUrl)
    //   }
    // }
  }
}
</script>
<style scoped lange="scss">
.page-header__wrap {
  height: 56px !important;
  padding: 0 12px;
}
.curRouter {
  display: flex;
}
.item {
  padding: 0 24px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  display: inline-block;
}
.item:hover {
  color: #1890ff;
}
.item.active {
  color: #1890ff;
  border-bottom: solid 1px #1890ff;
}
.item.active .path {
  color: #1890ff;
}
.item:hover .closeImg {
  display: inline;
}
.closeImg {
  position: absolute;
  top: 12px;
  right: 3px;
  color: #898989;
  display: none;
}
.path {
  color: #595959;
  text-decoration: none;
}
.list-btn {
  margin: 0 10px;
}
.site {
  color:#898989;
}
</style>