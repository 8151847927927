import Vue from 'vue'
import VueRouter from 'vue-router'
import {commonRouter} from "./routes"
import {getStorage} from '@/storage'
import store from '@/store'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: commonRouter
})

router.beforeEach((to, from, next) => {
  console.log(to)
  if(to.path != '/home/index' && to.path != '/login') {
    let str = {
      path: to.path,
      title: to.meta.title
    }
    store.commit('setRouterArr', str)
  }
  let token = getStorage('token')
  if(token){
    next()
  } else {
    to.path === "/login" ? next() : next('/login')
  }
})

export default router
